.about {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
  }
  
  .aboutTop {
    width: 100%;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .aboutBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .aboutBottom p {
    padding: 20px;
    padding-top: 0;
    text-align: justify;
    line-height: 23px;
    font-weight: 500;
  }
  
  .about .aboutBottom h1 {
    font-weight: 400;
    font-family: cursive;
    font-size: 60px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
    transform: translateY(-30px);
  }
  
  
  @media only screen and (max-width: 600px) {
    .aboutBottom {
      margin-top: 5px;
    }
    .about .aboutBottom h1 {
      font-weight: 400;
      font-family: cursive;
      font-size: 40px;
      height: 10px;
      transform: translateY(-10px);
    }
  }
  
  @media only screen and (max-width: 400px) {
    .about .aboutBottom h1 {
      font-size: 30px;
    }
  }
  