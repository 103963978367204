.menu {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .menu .menuTitle {
    font-family: cursive;
    font-size: 60px;
  }
  
  .menuList {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* MENU ITEM STYLING */
  
  .menuItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }
  .menuItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .menuItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .menuItem h1,
  .menuItem p {
    margin-left: 20px;
  }
  
  @media only screen and (max-width: 1300px) {
    .menuList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .menuList {
      grid-template-columns: 1fr;
    }
    .menu .menuTitle {
      font-size: 40px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .menuList {
      grid-template-columns: 1fr;
    }
    .menu .menuTitle {
      font-size: 30px;
    }
    .menuItem {
      border-radius: 15px;
      width: 250px;
      height: 300px;
      margin: 10px;
    }
    .menuItem div {
      width: 100%;
      height: 180px;
    }
    .menuItem h1 {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 400px) {
    .menuList {
      grid-template-columns: 1fr;
    }
    .menuItem {
      border-radius: 15px;
      width: 200px;
      height: 250px;
      margin: 10px;
    }
    .menuItem div {
      width: 100%;
      height: 140px;
    }
    .menuItem h1 {
      font-size: 22px;
    }
  }
  